<template>
    <app-wrapper>
        <template v-slot:child>
            <send-to-team-mate-modal :closeModal="() => showSendToTeamMateModal(false)" />
            <div class="w-90 center">
                <div class="flex justify-between items-center pv4-l pv3 mini-spacing">
                    <div>
                        <p class="pb2 f3 b">Paystack Integration</p>
                    </div>
                </div>
            </div>

            <main class="flex flex-wrap w-70 center mt3 adj-text">
                <div>
                    <header class="paystack-logo-header">
                        <img :src="require('@/assets/images/paystack-logo1.png')" alt="paystack-logo" width="300" />
                    </header>
                    <section>
                        <div class="flex justify-between items-center pb3">
                            <div class="b">Complete your integration</div>
                            <!-- <a href="#" 
                                class="paystack-section1-button pa2"
                                @click="showSendToTeamMateModal"
                            >Send as email to teammate</a> -->
                        </div>
                        <p class="paystack-section2-p">
                            To be able to get new events about payment, subscriptions and more from Paystack, we need you to set
                            your Paystack Live Webhook URL. You can find the live webhook URL in the settings page of your
                            Paystack dashboard. Paystack only supports one webhook per account at the moment so confirm the field
                            is empty. If empty, set the value to:
                        </p>
                    </section>
                    <section v-if="state.showWebhook">
                        <h6 class="mt4 mb1">Webhook URL:</h6>
                        <div class="pb2 copy-text-wrapper">
                            <input
                                @focus="$event.target.select()"
                                ref="paystackWebhookUrlRef"
                                v-model="state.paystackWebhookUrl"
                                id="paystackWebhook"
                                class="pa2 w-100 text"
                                type="text"
                                readonly
                                placeholder="Paystack webhook url"
                            />
                            <button @click="doCopy">
                                <span class="icon">
                                    <font-awesome-icon icon="fa-solid fa-copy" />
                                </span>
                            </button>
                        </div>
                    </section>
                    <section class="mt3 tc">
                        <img :src="require('@/assets/simplebks-paystack-integration.gif')" alt="paystack-integration-demo" />
                    </section>
                    <section class="paystack-section3">
                        <p class="paystack-back"><router-link :to="{ name: 'Integrations' }">&larr; Back</router-link></p>
                    </section>
                </div>
            </main>
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { copyText } from 'vue3-clipboard'
import AppWrapper from '@/layout/AppWrapper'
import SendToTeamMateModal from './SendToTeamMateModal.vue'

export default {
    name: 'PaystackWebhookDetails',
    components: { AppWrapper, SendToTeamMateModal },

    setup() {
        const store = useStore()

        const orgData = computed(() => store?.state?.Auth?.userData)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)

        const initialState = {
            processing: false,
            paystackSecretKey: '',
            showWebhook: false,
            paystackWebhookUrl: ''
        }

        const state = reactive(initialState)

        const doCopy = () => {
            copyText(state.paystackWebhookUrl, undefined, (error, event) => {
                if (!error) {
                    let copyText = document.querySelector('.copy-text-wrapper')
                    copyText.classList.add('active')
                    setTimeout(function() {
                        copyText.classList.remove('active')
                    }, 2500)
                }
            })
        }

        const showSendToTeamMateModal = (payload = true) => {
            store.dispatch('Settings/showSendToTeamMateModal', payload)
        }

        const initPaystackSettings = () => {
            store.dispatch('Settings/getIntegrationSettings').then(response => {
                if (response?.status) {
                    state.paystackSecretKey = response?.data?.paystack?.secretKey || ''
                    if (state.paystackSecretKey) {
                        state.showWebhook = true
                        state.paystackWebhookUrl = `${process.env.VUE_APP_API_URL}api/v1/accounts/${orgData.value?.orgId}/paystack/webhook`
                    }
                }
            })
        }

        onMounted(() => {
            initPaystackSettings()
        })

        return {
            orgData,
            state,
            doCopy,
            showSendToTeamMateModal,
            rolePermissions,
            role
        }
    }
}
</script>

<style>
.paystack-main {
    margin: 7% auto;
    width: 70%;
    box-sizing: border-box !important;
}
.paystack-logo-header {
    margin-bottom: 8%;
}
.paystack-section1-p {
    margin: 0;
}
.paystack-section1-input {
    margin: 8px 0;
}
.pay-width {
    width: 100%;
    box-sizing: border-box;
    height: 35px;
}
.paystack-section1-button {
    color: white;
    background-color: #132c8c;
    border: none;
    border-radius: 2px;
    margin-bottom: 2%;
}
.paystack-section2 {
    width: 80%;
}
.paystack-section2-p {
    line-height: 1.5em;
}
.paystack-section2-p a {
    text-decoration: none;
    color: #132c8c;
}
.paystack-section1-p span,
.paystack-section2-p span {
    background-color: #ffc107;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-weight: 700;
}
.paystack-section3 {
    width: 80%;
}
.paystack-back {
    margin-top: 50px;
    text-align: center;
}
.paystack-back a {
    text-decoration: none;
    color: #132c8c;
}
@media only screen and (max-width: 800px) {
    .paystack-logo-header {
        margin-bottom: 15%;
    }
}

.copy-text-wrapper {
    position: relative;
    padding: 5px;
    background: #fff;
    border: 1px solid #e3e8ee;
    border-radius: 4px;
    display: flex;
}
.copy-text-wrapper input.text {
    padding: 10px;
    font-size: 14px;
    color: #555;
    border: none !important;
    outline: none !important;
}
.copy-text-wrapper button {
    padding: 10px;
    border: none !important;
    outline: none !important;
    border-radius: 10px;
    cursor: pointer;
    background-color: unset !important;
    color: unset !important;
}

.copy-text-wrapper button:active {
    background: #809ce2;
}
.copy-text-wrapper button:before {
    content: 'Copied';
    position: absolute;
    top: -45px;
    right: 0px;
    background: #5c81dc;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 15px;
    display: none;
}
.copy-text-wrapper button:after {
    content: '';
    position: absolute;
    top: -20px;
    right: 25px;
    width: 10px;
    height: 10px;
    background: #5c81dc;
    transform: rotate(45deg);
    display: none;
}
.copy-text-wrapper.active button:before,
.copy-text-wrapper.active button:after {
    display: block;
}
</style>
