<template>
    <!-- form modal -->

    <div
        v-if="showSendToTeamMateModal"
        tabindex="0"
        @keydown.esc="closeModal"
  
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showSendToTeamMateModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-50-l w-90 pv2 center box-border" style="background: white" ref="bankAccountRef">
            <div class="flex justify-between pa3 box-border-bottom">
                <div class="f3 b">Send to Teammate</div>
                <div>
                    <router-link to="#">
                        <img :src="require('@/assets/images/Close-icon.svg')" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>

            <!--      Previously div tag-->
            <form class="entity-form" @submit.prevent="sendEmailToTeammate" ref="formRef">
                <div class="pa3">
                    <div class="flex flex-wrap items-end justify-between">
                        <div class="w-100-l w-100 pr2-l pb0-l pb3">
                            <label class="pb2">Teammate</label>
                            <v-select
                                label="name"
                                :options="members"
                                v-model="state.staff"
                            />
                        </div>
                    </div>
                    
                   <div class="flex flex-wrap justify-between items-end  box-border-top mt3 pt1">
                        <button
                            type="submit"
                            class="btn btn-primary w-100"
                            :disabled="state.disableSubmitButton"
                        >
                            Send
                        </button>
                    </div>
                </div>
            </form>
                    
        </div>
    </div>
</template>

<script>
import { reactive, computed, ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
    name: 'SendToTeamMateModal',
    components: { vSelect },
    props: {
        closeModal: Function,
    },

    setup(props) {
        const bankAccountRef = ref(null)
        const formRef = ref(null)
        const store = useStore()
        const showSendToTeamMateModal = computed(() => store.state?.Settings?.showSendToTeamMateModal)
        const members = computed(() => store.state.Settings.members)

        const initialState = {
            staff: '',
            disableSubmitButton: false
        }
        const state = reactive(initialState)

        watch(
            () => showSendToTeamMateModal?.value,
            () => {
                document.body.style.overflow = showSendToTeamMateModal.value ? 'hidden' : 'auto'
            }
        )

        const closeModal = () => {
            props.closeModal()
        }

        const sendEmailToTeammate = () => {
            state.disableSubmitButton = true

            store.dispatch('Settings/sendEmailToTeammate', state.bankDetails).then((resp) => {
                state.disableSubmitButton = false
                closeModal()
            }).catch((error) => {
                state.disableSubmitButton = false
                console.error(error)
            })
        }

        const handleOnClickOutsideModal = (e) => {
            if (bankAccountRef?.value && !bankAccountRef?.value.contains(e.target)) {
                closeModal()
            }
        }

        onMounted(() => {
            store.dispatch('Settings/getTeamMembers')
        })

        return {
            state,
            members,
            sendEmailToTeammate,
            showSendToTeamMateModal,
            formRef,
            bankAccountRef,
            handleOnClickOutsideModal
        }
    },
}
</script>

<style>
    .entity-form input[type='radio'] {
        background: unset;
        border: none;
        box-shadow: none;
        padding: unset;
        width: unset;
    }
</style>
